import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';

import { HelmetProvider } from 'react-helmet-async';
import './styles/index.css';

const helmetContext = {};

ReactDOM.render(
  <React.StrictMode>
    <HelmetProvider context={helmetContext}>
      <App />
    </HelmetProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
