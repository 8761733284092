// ./comps/ErrorBoundary.js
// implement logError

import React from "react"
import { I18n } from 'aws-amplify';


class ErrorBoundary extends React.Component {

  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }


  static getDerivedStateFromError(error) { return { hasError: true }; }
  componentDidCatch(error, errorInfo) {
    console.error(this.props.children)
  }

  render() {
    if (this.state.hasError) return (
      <div style={{padding: 50, fontSize: "1rem"}}>
        {I18n.get("App_errorBoundary")}{' '}🙁
      </div>
    );
    return this.props.children;
  }
}

export default ErrorBoundary
