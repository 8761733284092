import getUserLocale from "get-user-locale"

export const isBrowser = typeof window !== `undefined`
export const setUserLocal = (user) => (window.localStorage.statitUser = JSON.stringify(user))

const getUser = () => {
    try { 
      return JSON.parse(window.localStorage.statitUser) || undefined
    } catch (e) { return undefined }
  }

export const isSignedIn = () => {
  if (!isBrowser) return false
  return getUser()?.id
}

export const getCurrentUser = () => isBrowser && getUser()

export const logout = () => {
  if (!isBrowser) return
  setUserLocal(undefined)
}

export const lang = isSignedIn() ? (["fr", "en"].includes(getCurrentUser().lang) ? getCurrentUser().lang : "en") : (getUserLocale().slice(0, 2) === "fr" ? "fr" : "en")
