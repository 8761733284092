// App.js

import React, { Suspense, lazy } from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { I18n } from 'aws-amplify';

import Amplify from "@aws-amplify/core";
import aws_exports from "./aws-exports";

import ErrorBoundary from "./comps/ErrorBoundary"
import { lang } from './utils/auth'
import { dict } from './utils/txt'

const NotSupported = lazy(() => import("./pages/NotSupported"))

//const Index = lazy(() => import("./pages/Index"))

const About = lazy(() => import("./pages/About"))
const Account = lazy(() => import("./pages/Account"))
const AccountManage = lazy(() => import("./pages/AccountManage"))
const Asset = lazy(() => import("./pages/Asset"))
const ChartPage = lazy(() => import("./pages/ChartPage"))
const Collection = lazy(() => import("./pages/Collection"))
const Dashboard = lazy(() => import("./pages/Dashboard"))
const Index = lazy(() => import("./pages/Index"))
const New = lazy(() => import("./pages/New"))
const Post = lazy(() => import("./pages/Post"))
const Privacy = lazy(() => import("./pages/Privacy"))
const Public = lazy(() => import("./pages/Public"))
const Sign = lazy(() => import("./pages/Sign"))
const Terms = lazy(() => import("./pages/Terms"))
const Update = lazy(() => import("./pages/Update"))
const UserAnalytics = lazy(() => import("./pages/UserAnalytics"))
const MetricIframe = lazy(() => import("./iframes/MetricIframe"))


Amplify.configure(aws_exports);

const App = () => {

  const ua = window.navigator.userAgent;
  if (ua.indexOf("MSIE ") > -1) { return (<Suspense fallback={<div />}><NotSupported /></ Suspense>) }
  I18n.setLanguage(lang);
  I18n.putVocabularies(dict);

  return (
    <ErrorBoundary>
      <Router>
        <Suspense fallback={<div />}>
          <Switch>

            {/* front */}
            <Route exact path="/" component={Index} />
            <Route exact path="/statit" component={Index} />
            <Route exact path="/statit" component={Index} />

            <Route exact path="/help" component={() => { window.location.href = 'http://help.gostatit.com/'; return null; }} />

            <Route exact path="/about" component={About} />
            <Route exact path="/account" component={AccountManage} />
            <Route exact path="/new" component={New} />
            <Route exact path="/privacy" component={Privacy} />
            <Route exact path="/public" component={Public} />
            <Route exact path="/sign" component={Sign} />
            <Route exact path="/signpub" render={() => <Sign pub={true} />} />
            <Route exact path="/terms" component={Terms} />
            <Route exact path="/update" component={Update} />

            {/* admin */}
            <Route exact path="/u/:userslug" component={UserAnalytics} />

            {/* dashboards & charts */}
            <Route exact path="/i/:accountKey/:colKey/" render={() => <Collection tab="dashboards" />} />
            <Route exact path="/i/:accountKey/:colKey/:dashboardKey" component={Dashboard} />
            <Route exact path="/i/:accountKey/:colKey/:dashboardKey/:k1" component={ChartPage} />

            {/*for iframes*/}
            <Route exact path="/f/:accountKey/:colKey/:k1" component={MetricIframe} />
            <Route exact path="/f/:accountKey/:colKey/:k1/:k2" component={MetricIframe} />
            <Route exact path="/f/:accountKey/:colKey/:k1/:k2/:k3" component={MetricIframe} />
            <Route exact path="/f/:accountKey/:colKey/:k1/:k2/:k3/:k4" component={MetricIframe} />
            <Route exact path="/f/:accountKey/:colKey/:k1/:k2/:k3/:k4/:k5" component={MetricIframe} />
            <Route exact path="/f/:accountKey/:colKey/:k1/:k2/:k3/:k4/:k5/:k6" component={MetricIframe} />

            {/* serie/ collection source */}
            <Route exact path="/:accountKey" render={() => <Account tab="home" />} />
            <Route exact path="/:accountKey/cols" render={() => <Account tab="cols" />} />
            <Route exact path="/:accountKey/dashboards" render={() => <Account tab="dashboards" />} />
            <Route exact path="/:accountKey/members" render={() => <Account tab="members" />} />
            <Route exact path="/:accountKey/subs" render={() => <Account tab="subs" />} />

            <Route exact path="/:accountKey/:colKey" component={Collection} />
            <Route exact path="/:accountKey/:colKey/metrics" render={() => <Collection tab="metrics" />} />
            <Route exact path="/:accountKey/:colKey/charts" render={() => <Collection tab="dashboards" />} />
            <Route exact path="/:accountKey/:colKey/posts" render={() => <Collection tab="posts" />} />
            <Route exact path="/:accountKey/:colKey/members" render={() => <Collection tab="members" />} />
            <Route exact path="/:accountKey/:colKey/analytics" render={() => <Collection tab="analytics" />} />
            <Route exact path="/:accountKey/:colKey/post/:q" component={Post} />

            <Route exact path="/:accountKey/:colKey/:k1" component={Asset} />
            <Route exact path="/:accountKey/:colKey/:k1/:k2" component={Asset} />
            <Route exact path="/:accountKey/:colKey/:k1/:k2/:k3" component={Asset} />
            <Route exact path="/:accountKey/:colKey/:k1/:k2/:k3/:k4" component={Asset} />
            <Route exact path="/:accountKey/:colKey/:k1/:k2/:k3/:k4/:k5" component={Asset} />
            <Route exact path="/:accountKey/:colKey/:k1/:k2/:k3/:k4/:k5/:k6" component={Asset} />



          </ Switch>
        </ Suspense>

      </Router>
    </ErrorBoundary>
  )
}

export default App
